<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card class="card-app-design">
          <b-card-title class="mt-1 mb-75">
            User Management
          </b-card-title>
          <b-card-text class="font-small-2 mb-2">
            Manage User logins, permissions, and more.
          </b-card-text>
          <!-- button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="ToManageUser()"
          >
            Manage Users
          </b-button>
        </b-card>

      </b-col>
    </b-row>

  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  mounted() {
    this.LoadingEnd()
  },
  methods: {
    ToManageUser() {
      this.$router.push('/admin/userlist')
    },
  },
}
</script>
